<template>
  <div class="merchantcenter_page">
    <div
      style="
        box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
        background: #fff;
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
      "
    >
      <div class="page_title">{{ language.Merchantwithdrawal }}</div>
      <el-tabs
        style="margin-top: 10px"
        v-model="activeName"
        type="card"
        @tab-click="handleClick"
      >
        <el-tab-pane
          v-for="(item, index) in Currencylist"
          :key="index"
          :label="item"
          :name="item"
        ></el-tab-pane>
      </el-tabs>
      <div
        style="
          border: 1px solid #e4e7ed;
          border-top: none;
          padding: 10px;
          box-sizing: border-box;
          display: flex;
        "
      >
        <div v-if="showMerchantwithd">
          {{ language.Merchantwithdrawalnotactivatedpleasecontactrelevantpersonnel }}
        </div>
        <div
          style="display: flex; flex-direction: column; padding-bottom: 40px"
          v-if="!showMerchantwithd"
        >
          <!-- Epay支付帐号 -->
          <div
            style="
              position: relative;
              display: flex;
              align-items: center;
              margin-top: 20px;
            "
          >
            <div
              :style="
                languagetype == 'EN'
                  ? 'min-width: 200px;white-space: nowrap'
                  : 'min-width: 140px;white-space: nowrap'
              "
            >
              {{ language.Epaypaymentaccount }}:
            </div>
            <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
            <el-input
              style="box-sizing: border-box; width: 300px"
              v-model="info.linkman_email"
              disabled
              type="text"
              placeholder=""
            ></el-input>
          </div>

          <!-- 可提现余额 -->
          <div
            style="
              position: relative;
              display: flex;
              align-items: center;
              margin-top: 20px;
            "
          >
            <div
              :style="
                languagetype == 'EN'
                  ? 'min-width: 200px;white-space: nowrap'
                  : 'min-width: 140px;white-space: nowrap'
              "
            >
              {{ language.Withdrawablebalance }}:
            </div>
            <el-input
              style="box-sizing: border-box; width: 300px"
              v-model="Withdrawablebalance"
              type="text"
              disabled
              placeholder=""
            ></el-input>
          </div>

          <!-- 公司名 -->
          <div
            style="
              position: relative;
              display: flex;
              align-items: center;
              margin-top: 20px;
            "
          >
            <div
              :style="
                languagetype == 'EN'
                  ? 'min-width: 200px;white-space: nowrap'
                  : 'min-width: 140px;white-space: nowrap'
              "
            >
              {{ language.CustomerName }}:
            </div>
            <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
            <el-input
              style="box-sizing: border-box; width: 300px"
              v-model="info.name"
              disabled
              type="text"
              placeholder=""
            ></el-input>
          </div>

          <!-- 提现方式 -->
          <div
            style="
              position: relative;
              display: flex;
              align-items: center;
              margin-top: 20px;
            "
          >
            <div
              :style="
                languagetype == 'EN'
                  ? 'min-width: 200px;white-space: nowrap'
                  : 'min-width: 140px;white-space: nowrap'
              "
            >
              {{ language.Withdrawalmethod }}:
            </div>

            <template>
              <el-radio-group v-model="radio" @change="withMethod()" >
                <el-radio label="1">银行卡</el-radio>
                <el-radio label="2">支付宝</el-radio>
                <el-radio label="3" disabled>加密钱包</el-radio>
              </el-radio-group>
            </template>
          </div>

          <!-- 提现账户 -->
          <div
            style="
              position: relative;
              display: flex;
              align-items: center;
              margin-top: 20px;
            "
          >
            <div
              :style="
                languagetype == 'EN'
                  ? 'min-width: 200px;white-space: nowrap'
                  : 'min-width: 140px;white-space: nowrap'
              "
            >
              {{ language.Withdrawalaccount }}:
            </div>
            <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
            <el-select
              value-key="id"
              style="width: 300px"
              v-model="Withdrawalaccount"
              placeholder=""
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="Withdrawaltrue.type == 1 ? item.cardid : (Withdrawaltrue.type == 2 ? item.identity : item.wallet_address)"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>

          <!-- 提现金额 -->
          <div
            style="
              position: relative;
              display: flex;
              align-items: center;
              margin-top: 20px;
            "
          >
            <div
              :style="
                languagetype == 'EN'
                  ? 'min-width: 200px;white-space: nowrap'
                  : 'min-width: 140px;white-space: nowrap'
              "
            >
              {{ language.Cashwithdrawalamount }}:
            </div>
            <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
            <el-input
              style="box-sizing: border-box; width: 300px"
              v-model="RawWithdrawablebalance"
              type="text"
              placeholder=""
            ></el-input>
          </div>

          <!-- 邮箱 -->
          <div
            style="
              position: relative;
              display: flex;
              align-items: center;
              margin-top: 20px;
            "
          >
            <div
              :style="
                languagetype == 'EN'
                  ? 'min-width: 200px;white-space: nowrap'
                  : 'min-width: 140px;white-space: nowrap'
              "
            >
              {{ language.Email }}:
            </div>
            <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
            <el-input
              style="box-sizing: border-box; width: 300px"
              v-model="info.linkman_email"
              disabled
              type="text"
              placeholder=""
            ></el-input>
          </div>

          <!-- 验证码 -->
          <!-- <div
            style="
              position: relative;
              display: flex;
              align-items: center;
              margin-top: 20px;
            "
          >
            <div
              :style="
                languagetype == 'EN'
                  ? 'min-width: 200px;white-space: nowrap'
                  : 'min-width: 140px;white-space: nowrap'
              "
            >
              {{ language.VerificationCode }}:
            </div>
            <el-input
              style="box-sizing: border-box; width: 300px"
              v-model="VerificationCode"
              type="text"
              placeholder=""
            ></el-input>
            <div style="margin-left: 20px">
              <el-button style="height: 100%" v-if="time2" type="info">{{
                time2
              }}</el-button>
              <el-button type="primary" v-if="!time2" @click="Send_code(1)">{{
                language.Send_code
              }}</el-button>
            </div>
          </div> -->
          <!-- 支付密码 -->
          <div
            style="
              position: relative;
              display: flex;
              align-items: center;
              margin-top: 20px;
            "
          >
            <div
              :style="
                languagetype == 'EN'
                  ? 'min-width: 200px;white-space: nowrap'
                  : 'min-width: 140px;white-space: nowrap'
              "
            >
              {{ language.paymentpassword }}:
            </div>
            <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
            <div style="position: relative">
              <el-input
                style="box-sizing: border-box; width: 300px"
                v-model="paymentpassword"
                :type="passwordicon ? 'password' : 'text'"
                placeholder=""
              ></el-input>
              <span
                v-if="passwordicon"
                style=""
                @click="eyebtn5()"
                class="iconeye3"
              ></span>
              <span v-else style="" @click="eyebtn5()" class="iconeye4"></span>
            </div>
            <div style="margin-left: 20px">
              <span
                style="text-decoration: underline; color: #409eff; cursor: pointer"
                @click="gochangepass()"
                >{{ language.GotoEdit }}</span
              >
            </div>
          </div>
          <div style="display: flex; justify-content: center; margin-top: 20px">
            <el-button @click="addbank()" type="primary">{{
              language.determine
            }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  usergetPayConf,
  info,
  bankcard,
  wallet,
  alipay,
  user,
  emssend,
  withadd,
} from "../../api/index.js";
export default {
  data() {
    return {
      passwordicon: true,
      activeName: "",
      Currencylist: [],
      languagetype: "",
      language: "",
      info: "",
      paymentpassword: "",
      time2: "",
      VerificationCode: "",
      Cashwithdrawalamount: "",
      options: "",
      Withdrawalaccount: "",
      Withdrawablebalance: "",
      RawWithdrawablebalance: "",
      Withdrawaltrue: {
        type: 1,
      },
      money2: "",
      thb_money: "",
      usdt_money: "",
      inr_money: "", //印度币
      wanbi_money: "", //wanbi
      gobi_money: "", //gobi
      rmb_money: "", //人民币
      passageId: "",
      transferFromId: "",
      usdtChannel: "",
      showMerchantwithd: false,
      withlistmsg: "",
      radio: '1'
    };
  },
  created() {
    // 判断语言
    this.changelanguage();
    info().then((res) => {
      this.info = res.data;
    });
    this.user();
  },
  watch: {
    // 判断语言
    "$store.state.language"() {
      this.changelanguage();
    },
  },
  methods: {
    user() {
      user().then((res) => {
        if (res.code == 1) {
          // if (res.data.userinfo.pid == 0) {
          //   this.Email_of_person_in_charge = res.data.userinfo.username;
          // }
          this.money2 = res.data.userinfo.money;
          this.thb_money = res.data.userinfo.thb_money;
          this.usdt_money = res.data.userinfo.usdt_money;
          this.inr_money = res.data.userinfo.inr_money;
          this.wanbi_money = res.data.userinfo.wanbi_money;
          this.gobi_money = res.data.userinfo.gobi_money;
          // this.id = res.data.userinfo.id;
          this.rmb_money = res.data.userinfo.cny_money;
          this.init();
        }
      });
    },
    eyebtn5() {
      this.passwordicon = !this.passwordicon;
    },
    getlist() {
      if (
        // this.activeName == "MYR" ||
        // this.activeName == "CNY" ||
        // this.activeName == "THB" ||
        // this.activeName == "INR"
        this.Withdrawaltrue.type == 1
      ) {
        // this.Withdrawaltrue.type = 1;
        let data = {
          currency: this.activeName,
        };
        bankcard(data).then((res) => {
          this.options = res.data.list;
        });

      }else if (
        // this.activeName == "CNY"
        this.Withdrawaltrue.type == 2
        ) {
        // this.Withdrawaltrue.type = 2;
        let data = {
          currency: this.activeName,
        };
        alipay(data).then((res) => {
          this.options = res.data.list;
        });
        
      } else {
        this.Withdrawaltrue.type = 3;
        let data = {
          currency: this.activeName,
        };
        wallet(data).then((res) => {
          this.options = res.data.list;
        });
      }
    },
    init() {
      let data = {
        limit: 100,
        page: 1,
      };
      usergetPayConf(data).then((res) => {
        res.data.list.map((item) => {
          if (!this.Currencylist.includes(item.currency) && item.mchWithSwitch == 1) {
            this.Currencylist.push(item.currency);
          }
        });
        this.activeName = this.Currencylist[0];
        this.usergetPayConf();
        this.changeWithdrawablebalance();
        this.getlist();
      });
    },
    // 获取渠道
    usergetPayConf() {
      let data = {
        currency: this.activeName,
      };
      usergetPayConf(data).then((res) => {
        let list = [];
        res.data.list.map((item) => {
          if (item.mchWithSwitch == 1) {
            list.push(item);
          }
        });
        if (!list[0]) {
          this.showMerchantwithd = true;
          this.$message({
            message: this.language
              .Merchantwithdrawalnotactivatedpleasecontactrelevantpersonnel,
            type: "warning",
          });
          return;
        }
        let num = Math.floor(Math.random() * list.length) + 1 - 1;
        // usdtChannel
        if (this.activeName == "USDT") {
          this.usdtChannel = list[num].passageId.split("-")[1];
        }
        // this.usdtChannel = list[num].passageId;
        this.passageId = list[num].passageId;
        this.transferFromId = list[num].type;
        this.withlistmsg = list[num];
      });
    },
    handleClick(tab) {
      this.RawWithdrawablebalance = "";
      this.Cashwithdrawalamount = "";
      this.paymentpassword = "";
      this.VerificationCode = "";
      this.Withdrawalaccount = "";
      this.activeName = tab.label;
      this.usergetPayConf();
      this.changeWithdrawablebalance();
      this.getlist();
    },
    gochangepass() {
      this.$router.push("/changepassword");
    },
    //切换提现方式
    withMethod() {
      let that = this;
      that.Withdrawaltrue.type = that.radio;
      // console.log(that.Withdrawaltrue.type);

      // 重新拉取账户列表
      that.getlist()
    },
    // 提现
    addbank() {
      let type = "";
      if (this.Withdrawaltrue.type == 1) {
        type = 0;
      }else if (this.Withdrawaltrue.type == 2) { //支付宝
        type = 1;
      } else {
        type = 2;
      }

      if (!this.RawWithdrawablebalance) {
        this.$message({
          message: this.language.RawWithdrawablebalance + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      // if (!this.VerificationCode) {
      //   this.$message({
      //     message: this.language.VerificationCode + this.language.cannotbeempty,
      //     type: "warning",
      //   });
      //   return;
      // }
      if (!this.paymentpassword) {
        this.$message({
          message: this.language.paymentpassword + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      let data = {
        type: type,
        money: this.RawWithdrawablebalance,
        currency: this.activeName,
        pay_pwd: this.paymentpassword,
        captcha: this.VerificationCode,
        passageId: this.passageId,
        fromId: this.transferFromId,
      };
      if (this.activeName == "USDT") {
        data.usdtChannel = this.usdtChannel;
      }
      if (this.Withdrawaltrue.type == 1) {
        data.bankcard_id = this.Withdrawalaccount.id;
      }else if (this.Withdrawaltrue.type == 2) { //支付宝
        data.alipay_id = this.Withdrawalaccount.id;
      } else if (this.Withdrawaltrue.type == 0) {
        data.wallet_id = this.Withdrawalaccount.id;
      }
      withadd(data).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: this.language.Successfullyapplied,
            type: "success",
          });
          this.RawWithdrawablebalance = "";
          this.Cashwithdrawalamount = "";
          this.paymentpassword = "";
          this.VerificationCode = "";
          this.Withdrawalaccount = "";
          this.Balancewithdrawalshow = false;
          
          // 余额局部刷新
          this.user();
        }
      });
    },
    // 发送验证码
    Send_code() {
      this.time2 = 60;
      let event = "withAdd";
      let data = {
        email: this.info.linkman_email,
        event: event,
      };
      this.timeInterval();
      emssend(data).then(() => {});
    },
    // 验证码倒计时
    timeInterval() {
      let that = this;
      this.sendtimeInterval = setInterval(() => {
        that.time2 = that.time2 - 1;
        if (that.time2 == 0) {
          clearInterval(that.sendtimeInterval);
        }
      }, 1000);
    },
    changeWithdrawablebalance() {
      if (this.activeName == "MYR") {
        this.Withdrawablebalance = this.money2;
      } else if (this.activeName == "CNY") {
        this.Withdrawablebalance = this.rmb_money;
      } else if (this.activeName == "THB") {
        this.Withdrawablebalance = this.thb_money;
      } else if (this.activeName == "INR") {
        this.Withdrawablebalance = this.inr_money;
      } else if (this.activeName == "USDT") {
        this.Withdrawablebalance = this.usdt_money;
      } else if (this.activeName == "WanBi") {
        this.Withdrawablebalance = this.wanbi_money;
      } else if (this.activeName == "GoBi") {
        this.Withdrawablebalance = this.gobi_money;
      }
      this.RawWithdrawablebalance = this.Withdrawablebalance;
      this.Withdrawablebalance = this.Withdrawablebalance + this.activeName;
    },
    // 获取语言
    changelanguage() {
      this.languagetype = this.$store.state.language;
      this.language = this.$units();
    },
  },
};
</script>
<style scoped>
.merchantcenter_page {
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.page_title {
  font-size: 26px;
  font-weight: 600;
}
.iconeye3 {
  display: inline-block;
  width: 33px;
  height: 22px;
  position: absolute;
  z-index: 9;
  top: 9px;
  cursor: pointer;
  right: 0;
  background: url("../../static/icon-login.png") no-repeat 0 -43px;
}

.iconeye4 {
  display: inline-block;
  width: 33px;
  height: 22px;
  position: absolute;
  z-index: 9;
  top: 9px;
  cursor: pointer;
  right: 0;
  background: url("../../static/icon-login.png") no-repeat -55px -43px;
}
* /deep/ .el-tabs__header {
  margin: 0;
}
</style>
